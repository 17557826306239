import React, { Component } from 'react';
import './App.css';

// import components
import Main from '../src/Components/Main';

class App extends React.Component {

  render(){
    return(
      <div>
        <Main />
      </div>
    )
  }
}

export default App;
