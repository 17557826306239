import React, { Component } from 'react';

import Logo from '../../StaticResources/logo-positive.png';
import Sfondo from '../../StaticResources/sfondo.png';

import Config from '../../Config'
import Utility from '../../Utility'

class LoginScreen extends React.Component {

    state = {
        access: false,
        username: '',
        password: '',
    }

    doLogin() {
        const formResult = this.formValidation()
        console.log(this.state)
        if(formResult === true){
            // fai il login
            const url = Config.apiUrl+'/wp-json/jwt-auth/v1/token';
            fetch(url,{
                method: "POST",
                body: JSON.stringify({username:this.state.username,password:this.state.password}),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
            .then((resp) => resp.json()) // Transform the data into json
            .then((data) => {
                // server response...
                if(data.token){
                    if(data.ruolo == 'utente'){
                        // è un utente app... non puo entrare nel pannello
                        // lo rimando alla pagina di login
                        this.props.history.push('/');
                    } else {
                        // save token and redirect...
                        localStorage.setItem('token',data.token);
                        localStorage.setItem('ruolo',data.ruolo);
                        localStorage.setItem('user_email',data.user_email);
                        this.setState({access:true});
                    }
                } else {
                    this.setState({username:''});
                    this.setState({password:''});
                    alert('Credenziali errate. Riprova!');
                }
            })
            .catch((error)=>{
                console.log("Login API call error: " + error.message);
            });
        } else {
            // alert errors...
            alert(formResult);
        }
    }

    formValidation() {
        // form validation
        const form = [
            {
                fieldValue: this.state.username,
                fieldName: 'Email',
                rules: ['required']
            },
            {
                fieldValue: this.state.password,
                fieldName: 'Password',
                rules: ['required']
            }
        ];

        return Utility.formValidator(form);
    }

    render(){
        return(
            <div style={{zIndex:'100000',position:'fixed',top:0,left:0,width:'100%',height:'100%',backgroundImage:'url('+Sfondo+')',backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'center bottom'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-md-4 col-md-offset-4 text-center">
                            <div style={{background:'white',borderRadius:'10px',boxShadow:'0 5px 11px rgba(0,0,0,0.18)',padding:'15px',marginTop:'50px'}}>
                                <img src={Logo} style={{width:'150px',marginBottom:'20px'}} />
                                
                                {
                                    this.state.access === false
                                    ?
                                    <div>
                                        <input type="text" placeholder="Email" className="form-control" value={this.state.username} onChange={(text) => this.setState({username:text.target.value})} />
                                        <br></br>
                                        <input type="password" placeholder="Password" className="form-control" value={this.state.password} onChange={(text) => this.setState({password:text.target.value})} />
                                        
                                        <br></br>
                                        <input type="button" className="btn btn-warning" value="ACCEDI" onClick={() => this.doLogin()} />
                                        <br></br>
                                        <br></br>
                                        <a style={{fontSize:'12px'}} href="">Recupera password</a>
                                    </div>
                                    :
                                    <div>
                                        <h4>Bentornato!</h4>
                                        <br></br>
                                        <a className="btn btn-primary" href="/dashboard">ENTRA</a>
                                    </div>
                                }
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginScreen;