import React, { Component } from 'react';

class ServicesScreen extends React.Component {
    render(){
        return(
            <div>
                ServicesScreen
            </div>
        )
    }
}

export default ServicesScreen;