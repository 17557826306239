import React, { Component } from 'react';
import {Switch,Route,Link,NavLink, withRouter} from 'react-router-dom'

// import screens 
import LoginScreen from '../../Screens/Login';
import DashboardScreen from '../../Screens/Dashboard';
import IdvScreen from '../../Screens/Idv';
import UserAppScreen from '../../Screens/UserApp';
import UserPanelScreen from '../../Screens/UserPanel';
import CompletedServicesScreen from '../../Screens/CompletedServices';
import InvoicesSentScreen from '../../Screens/InvoicesSent';
import ServicesScreen from '../../Screens/Services';
import RequestsReceivedScreen from '../../Screens/RequestsReceived';
import ActiveServicesScreen from '../../Screens/ActiveServices';
import InvoicesReceivedScreen from '../../Screens/InvoicesReceived';


// import components
import Logo from '../../StaticResources/logo.png';

class Main extends React.Component {

    state = {
      ruolo: localStorage.getItem('ruolo')
    }

    renderMenu(){
      const ruolo = this.state.ruolo;
      if(ruolo == 'admin_griphone'){
        return (
          <div>
            <ul>
              <li><NavLink to='/dashboard' exact activeClassName='is-active'>Dashboard</NavLink></li>
              <li><NavLink to='/idv' activeClassName='is-active'>Idv</NavLink></li>
              <li><NavLink to='/utenti-app' activeClassName='is-active'>Utenti app</NavLink></li>
              <li><NavLink to='/utenti-pannello' activeClassName='is-active'>Utenti pannello</NavLink></li>
              <li><NavLink to='/interventi-conclusi' activeClassName='is-active'>Interventi conclusi</NavLink></li>
              <li><NavLink to='/fatture-emesse' activeClassName='is-active'>Fatture emesse</NavLink></li>
              <li><NavLink to='/servizi' activeClassName='is-active'>Servizi</NavLink></li>
            </ul>
            <h5 style={{padding: '30px 15px 20px 15px', color:'#cccccc',margin:0}}>AREA OPERATIVA</h5>
            <ul>
              <li><NavLink to='/richieste-ricevute' activeClassName='is-active'>Richieste ricevute</NavLink></li>
              <li><NavLink to='/servizi-attivi' activeClassName='is-active'>Servizi attivi</NavLink></li>
            </ul>
          </div>
        )
      } else if (ruolo == 'board_griphone'){
        return (
          <div>
            <ul>
              <li><NavLink to='/dashboard' exact activeClassName='is-active'>Dashboard</NavLink></li>
            </ul>
          </div>
        )
      } else if (ruolo == 'admin_idv'){
        return (
          <div>
            <ul>
              <li><NavLink to='/dashboard' exact activeClassName='is-active'>Dashboard</NavLink></li>
              <li><NavLink to='/utenti-pannello' activeClassName='is-active'>Utenti pannello</NavLink></li>
              <li><NavLink to='/interventi-conclusi' activeClassName='is-active'>Interventi conclusi</NavLink></li>
              <li><NavLink to='/fatture-ricevute' activeClassName='is-active'>Fatture ricevute</NavLink></li>
            </ul>
            <h5 style={{padding: '30px 15px 20px 15px', color:'#cccccc',margin:0}}>AREA OPERATIVA</h5>
            <ul>
              <li><NavLink to='/richieste-ricevute' activeClassName='is-active'>Richieste ricevute</NavLink></li>
              <li><NavLink to='/servizi-attivi' activeClassName='is-active'>Servizi attivi</NavLink></li>
            </ul>
          </div>
        )
      } else if (ruolo == 'finance_idv'){
        return (
          <div>
            <ul>
              <li><NavLink to='/dashboard' exact activeClassName='is-active'>Dashboard</NavLink></li>
              <li><NavLink to='/interventi-conclusi' activeClassName='is-active'>Interventi conclusi</NavLink></li>
            </ul>
          </div>
        )
      } else if (ruolo == 'operatore') {
        return (
          <div>
            <ul>
              <li><NavLink to='/interventi-conclusi' activeClassName='is-active'>Interventi conclusi</NavLink></li>
            </ul>
            <h5 style={{padding: '30px 15px 20px 15px', color:'#cccccc',margin:0}}>AREA OPERATIVA</h5>
            <ul>
              <li><NavLink to='/richieste-ricevute' activeClassName='is-active'>Richieste ricevute</NavLink></li>
              <li><NavLink to='/servizi-attivi' activeClassName='is-active'>Servizi attivi</NavLink></li>
            </ul>
          </div>
        )
      }
    }

    render(){
      return (
        <div>
            <div style={{zIndex:1000,background:'#257993',borderBottom:'5px solid #fcc020',boxShadow:'0 5px 11px rgba(0,0,0,0.12)',position:'fixed',top:0,left:'20%',width:'80%', height:50,padding:'0 15px'}}>
                <div className="row">
                    <div className="col-xs-6">
                    </div>
                    <div className="col-xs-6 text-right">
                        <h5 style={{margin:0,marginTop:6,color:'white'}}>
                          {
                            localStorage.getItem('user_email')
                          }
                        </h5>
                        <span style={{fontSize:12,color:'#eeeeee'}}>
                          {
                            localStorage.getItem('ruolo')
                          }
                        </span>
                    </div>
                </div>
            </div>
            <div style={{background:'white',zIndex:'1000',overflow:'auto',boxShadow:'5px 0px 11px rgba(0,0,0,0.12)',width:'20%',height:'100%',position:'fixed', top: 0, left:0}}>
              <div style={{color: 'white',background:'#257993',padding:20,textAlign:'center',borderBottom:'5px solid #fcc020'}}>
                <img src={Logo} style={{width:100}} />
              </div>
              <div className="menu" style={{}}>
                {
                  this.renderMenu()
                }
                <br></br>
                <ul>
                  <li><a href="#" onClick={() => {localStorage.removeItem('token');localStorage.removeItem('ruolo');localStorage.removeItem('user_email');this.props.history.push('/')}}>Esci</a></li>
                </ul>
              </div>
            </div>
            <div style={{padding:20,paddingTop:70,paddingBottom:50,width:'80%',position:'absolute', top: 0, left:'20%'}}>
              <div className="row">
                <div className="col-xs-12">
                <Switch>
                  <Route exact path='/' component={LoginScreen}/>
                  <Route exact path='/dashboard' component={DashboardScreen}/>
                  <Route exact path='/idv' component={IdvScreen}/>
                  <Route exac path='/utenti-app' component={UserAppScreen}/>
                  <Route exac path='/utenti-pannello' component={UserPanelScreen}/>
                  <Route exac path='/interventi-conclusi' component={CompletedServicesScreen}/>
                  <Route exac path='/fatture-emesse' component={InvoicesSentScreen}/>
                  <Route exac path='/servizi' component={ServicesScreen}/>
                  <Route exac path='/richieste-ricevute' component={RequestsReceivedScreen}/>
                  <Route exac path='/servizi-attivi' component={ActiveServicesScreen}/>
                  <Route exac path='/fatture-ricevute' component={InvoicesReceivedScreen}/>
                </Switch>
                </div>
              </div>
            </div>
        </div>
      )
    }
  }

  export default withRouter(Main);