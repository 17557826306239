import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Config from '../../Config'
import Utility from '../../Utility'


// use jQuery
import jQuery from 'jquery';
window.jQuery = jQuery;

const theme = createMuiTheme({
    palette: {
        primary: { main: '#257993' },
        secondary: { main: '#257993' }, 
    },
    typography: {
      useNextVariants: true,
      fontSize: '14px'
    },
});

// funzione per ordinare...
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// funzione per stabilizzare l'ordinamento...
function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

// ordina in base al clic sulla colonna (asc o desc)
function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// Nomi colonne
const rows = [
  { id: 'id', numeric: false, disablePadding: true, label: 'ID' },
  { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
  { id: 'cognome', numeric: false, disablePadding: false, label: 'Cognome' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'provincia', numeric: false, disablePadding: false, label: 'Provincia' },
  { id: 'actions', numeric: true, disablePadding: false, label: '' },
];

// intestazione tabella...
class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
        <TableHead>
            <TableRow>
            <TableCell padding="checkbox">
                <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                />
            </TableCell>
            {rows.map(row => {
                return (
                <TableCell
                    key={row.id}
                    numeric={row.numeric}
                    padding={row.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === row.id ? order : false}
                >
                    <Tooltip
                    title="Ordina"
                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                    >
                    <TableSortLabel
                        active={orderBy === row.id}
                        direction={order}
                        onClick={this.createSortHandler(row.id)}
                    >
                        {row.label}
                    </TableSortLabel>
                    </Tooltip>
                </TableCell>
                );
            }, this)}
            </TableRow>
        </TableHead>
    );
  }
}

// toolbar della tabella...
let EnhancedTableToolbar = props => {
  const { selected, numSelected, classes } = props;

  return (
    <Toolbar style={{display:'block'}}>
        <div className="row" style={{height:'64px',lineHeight:'64px'}}>
            <div className="col-xs-5">
                {numSelected > 0 ? (
                <span style={{fontSize:22}}>
                    {numSelected} selezionati
                </span>
                ) : (
                <span style={{fontSize:22}}>
                    Utenti app
                </span>
                )}
            </div>
            <div className="col-xs-7 text-right">
                <button className="btn btn-warning" onClick={() => alert('call api to export csv')} style={{marginRight:'10px'}}>ESPORTA</button>
                <button className="btn btn-primary" data-toggle="modal" data-target="#search-modal">CERCA</button>
            </div>
        </div>
    </Toolbar>
  );
};

// Screen Component
class UserAppScreen extends React.Component {

  state = {
    isLoading: true,
    order: 'asc',
    orderBy: 'nome',
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 10,
    // for snackbar 
    snackShow : false,
    snackMessage: '',
    // for searching...
    searchNome: '',
    searchEmail: '',
    searchProv: '',
    // for create / edit item...
    itemId: '',
    itemNome: '',
    itemCognome: '',
    itemEmail: '',
    itemTelefono: '',
    itemVia: '',
    itemCitta: '',
    itemCap: '',
    itemProvincia: '',
    itemDataNascita: '',
    itemLuogoNascita: '',
    itemSesso: '',
    itemCF: '',
    itemAzienda: '',
    itemRagioneSociale: '',
    // sending data
    sendingData: false
  };

  componentWillMount(){  
    const token = localStorage.getItem('token');
    if(token && token !== null){
        Utility.checkToken(token)
        .then((resp) => resp.json()) // Transform the data into json
        .then((data) => {
            // server response...
            if(data.id == 0){
                this.props.history.push('/')
            } else {
                // controllo se come utente ho abilità a vedere questa screen
                const control = Utility.acl(data.tipologia,'utenti-app').control;
                if(control == false){
                    // redirect a login ... non hai il permesso
                    this.props.history.push('/')
                }
            }
        })
        .catch((error)=>{
            console.log("Verification token API error: " + error.message);
        });
    } else {
        this.props.history.push('/')
    }
}

  componentDidMount(){
      this.getData();
  }

  // get Filter string
  getFilter(){
    // parto già filtrando per utente app...
    let searchString = '&ruolo=utente';
    if(this.state.searchNome != ''){
        searchString += '&nome='+this.state.searchNome;
    }
    if(this.state.searchEmail != ''){
        searchString += '&email='+this.state.searchEmail;
    }
    if(this.state.searchProv != ''){
        searchString += '&provincia='+this.state.searchProv;
    }
    // tolgo il primo carattere dalla stringa
    searchString = searchString.substr(1);
    // aggiungo il "?" davanti alla stringa
    searchString = "?"+searchString;
    return searchString;
  }

  clearFilter(){
    this.setState({searchNome: ''});
    this.setState({searchEmail: ''});
    this.setState({searchProv: ''});
  }
  
  // funziona che preleva e aggiorna i dati della tabella in base anche alla ricerca...
  getData(){
      this.setState({isLoading: true});
      const searchString = this.getFilter();
      // prelevo i dati dal server...
      const url = Config.apiUrl+'/wp-json/griphone/v1/utenti'+searchString;
      fetch(url,{
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
      })
      .then((resp) => resp.json()) // Transform the data into json
      .then((data) => {
          this.setState({data: data});
          this.setState({isLoading: false});
      })
      .catch((error)=>{
          console.log("User API call error: " + error.message);
      });
  }

  // preleva i dati di un item da modificare...
  getItemData(id){
      // prelevo i dati dal server...
      const url = Config.apiUrl+'/wp-json/griphone/v1/utente/'+id;
      fetch(url,{
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
      })
      .then((resp) => resp.json()) // Transform the data into json
      .then((data) => {
          this.setState({itemId: data.id});
          this.setState({itemNome: data.nome});
          this.setState({itemCognome: data.cognome});
          this.setState({itemEmail: data.email});
          this.setState({itemTelefono: data.tel});
          this.setState({itemVia: data.via});
          this.setState({itemCitta: data.citta});
          this.setState({itemCap: data.cap});
          this.setState({itemProvincia: data.provincia});
          this.setState({itemDataNascita: data.data_nascita});
          this.setState({itemLuogoNascita: data.luogo_nascita});
          this.setState({itemSesso: data.sesso});
          this.setState({itemCF: data.piva});
          this.setState({itemAzienda: data.azienda});
          if(data.azienda == true){
            this.setState({itemRagioneSociale: data.nome_azienda});
          }

          jQuery('#item-modal').modal('show');
      })
      .catch((error)=>{
          console.log("User API call error: " + error.message);
      });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
        <div>
          <MuiThemeProvider theme={theme}>
            <Snackbar
              anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
              open={this.state.snackShow}
              autoHideDuration={5000}
              onClose={() => {this.setState({snackShow:false}); this.setState({snackMessage:''}) }}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id" style={{fontSize:'14px'}}>{this.state.snackMessage}</span>}
            />
            <EnhancedTableToolbar numSelected={selected.length} selected={selected} />
            <div>
                <Table aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={this.handleSelectAllClick}
                        onRequestSort={this.handleRequestSort}
                        rowCount={data.length}
                    />
                    <TableBody>
                    {stableSort(data, getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(n => {
                        const isSelected = this.isSelected(n.id);
                        return (
                            <TableRow
                            hover
                            onClick={event => this.handleClick(event, n.id)}
                            role="checkbox"
                            aria-checked={isSelected}
                            tabIndex={-1}
                            key={n.id}
                            selected={isSelected}
                            >
                              <TableCell padding="checkbox">
                                  <Checkbox checked={isSelected} />
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                  {n.id}
                              </TableCell>
                              <TableCell>
                                {n.nome}
                              </TableCell>
                              <TableCell>
                                {n.cognome}
                              </TableCell>
                              <TableCell>
                                {n.email}
                              </TableCell>
                              <TableCell>
                                {n.provincia}
                              </TableCell>
                              <TableCell numeric>
                                <a className="btn btn-default" onClick={() => this.getItemData(n.id)}>
                                  <span className="glyphicon glyphicon-eye-open"></span>
                                </a>
                              </TableCell>
                            </TableRow>
                        );
                        })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={6} />
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                style={{fontSize:14}}
                labelRowsPerPage="Righe per pagina: "
                labelDisplayedRows={({ from, to, count }) => from+"-"+to+" di " +count}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
        </MuiThemeProvider>

          {/* modal ricerca */}
          <div className="modal fade" id="search-modal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                  <h4 className="modal-title" id="myModalLabel">Ricerca avanzata</h4>
                </div>
                <div className="modal-body">

                  <label>Nome cognome</label>
                  <input type="text" value={this.state.searchNome} className="form-control" onChange={(text) => this.setState({searchNome: text.target.value})} />
                  <br></br>
                  <label>Email</label>
                  <input type="text" value={this.state.searchEmail} className="form-control" onChange={(text) => this.setState({searchEmail: text.target.value})} />
                  <br></br>
                  <label>Provincia</label>
                  <select className="form-control" value={this.state.searchProv} onChange={(text) => this.setState({searchProv: text.target.value})}>
                    <option value="">Scegli provincia</option>
                      {
                        Config.province.map((value,index) => (
                          <option key={value} value={value}>{value}</option>
                        ))
                      }
                  </select>
                  <br></br>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default" onClick={() => this.clearFilter()} >PULISCI FILTRI</button>
                  <button type="button" className="btn btn-primary" onClick={()=>this.getData()} data-dismiss="modal">CERCA</button>
                </div>
              </div>
            </div>
          </div>

          {/* modal item */}
          <div className="modal fade" id="item-modal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                  <h4 className="modal-title" id="myModalLabel">
                    {
                        this.state.itemNome + " " + this.state.itemCognome
                    }
                  </h4>
                </div>
                <div className="modal-body">
                  <b>ID: </b> {this.state.itemId} <br></br>
                  <b>NOME: </b> {this.state.itemNome} <br></br>
                  <b>COGNOME: </b> {this.state.itemCognome} <br></br>
                  <b>EMAIL: </b> {this.state.itemEmail} <br></br>
                  <b>TELEFONO: </b> {this.state.itemTelefono} <br></br>
                  <b>VIA: </b> {this.state.itemVia} <br></br>
                  <b>CITTA': </b> {this.state.itemCitta} <br></br>
                  <b>CAP: </b> {this.state.itemCap} <br></br>
                  <b>PROVINCIA: </b> {this.state.itemProvincia} <br></br>
                  <b>DATA DI NASCITA: </b> {this.state.itemDataNascita} <br></br>
                  <b>LUOGO DI NASCITA: </b> {this.state.itemLuogoNascita} <br></br>
                  <b>SESSO: </b> {this.state.itemSesso} <br></br>
                  {
                      this.state.itemAzienda == true
                      ?
                      <div>
                        <b>AZIENDA: </b> {this.state.itemRagioneSociale} <br></br>
                        <b>P.IVA: </b> {this.state.itemCF} <br></br>
                      </div>
                      :
                      <div>
                          <b>CODICE FISCALE: </b> {this.state.itemCF} <br></br>
                      </div>
                  }

                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal">CHIUDI</button>
                </div>
              </div>
            </div>
          </div>


        </div>

    );
  }
}

export default UserAppScreen;
