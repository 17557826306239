import Config from '../Config'

class Utility {
    fieldValidator(fieldValue, fieldName, rules){
        let errors = '';
        if(rules && rules.length > 0){
            rules.forEach(rule => {
                if(rule == 'required'){
                    if(fieldValue == '' || fieldValue.length == 0){
                        errors += 'Il campo "' + fieldName + '" è obbligatorio\n';
                    }
                }
                if (rule == 'numeric') {
                    let onlynumber = /^[0-9]*$/;
                    if(!onlynumber.test(fieldValue)){
                        errors += 'Il campo "' + fieldName + '" accetta solo un valore numerico\n';
                    }
                } 
                if (rule == 'isEmail') {
                    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if(!re.test(fieldValue)){
                        errors += 'Il campo "' + fieldName + '" accetta solo una email valida\n';
                    }
                }
            });
        }
        // return
        if(errors == ''){
            return true;
        } else {
            return errors;
        }
    }

    formValidator(form){
        let errors = '';
        form.map((value,index) => {
            let res = this.fieldValidator(value.fieldValue, value.fieldName, value.rules);
            res != true ? errors += res : errors = errors;
        })
        // return 
        if(errors == ''){
            return true;
        } else {
            return errors;
        }
    }

    async checkToken(token) {
        // verify token api...
        const url = Config.apiUrl+'/wp-json/griphone/v1/validate';
        let response = await fetch(url,{
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        return response
    }

    acl(role, screen){
        let endpoint = [];
        if(role == 'admin_griphone'){
            endpoint = ['dashboard','idv','utenti-app','utenti-pannello','interventi-comclusi','fatture-emesse','servizi','richieste-ricevute','servizi-attivi'];
        } else if (role == 'board_griphone') {
            endpoint = ['dashboard'];
        } else if (role == 'admin_idv') {
            endpoint = ['dashboard','utenti-pannello','interventi-conclusi','fatture-ricevute','richieste-ricevute','servizi-attivi']
        } else if (role == 'finance_idv'){
            endpoint = ['dashboard','interventi-conclusi'];
        } else if (role == 'operatore') {
            endpoint = ['interventi-conclusi','richieste-ricevute','servizi-attivi'];
        }
        // torna true se trova corrispondenza, altrimenti false...
        const response = {
            control: endpoint.includes(screen),
            links: endpoint
        }
        return response;
    }
}

export default new Utility;