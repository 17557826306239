import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Config from '../../Config'
import Utility from '../../Utility'


// use jQuery
import jQuery from 'jquery';
window.jQuery = jQuery;

const theme = createMuiTheme({
    palette: {
        primary: { main: '#257993' },
        secondary: { main: '#257993' }, 
    },
    typography: {
      useNextVariants: true,
      fontSize: '14px'
    },
});

// funzione per ordinare...
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// funzione per stabilizzare l'ordinamento...
function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

// ordina in base al clic sulla colonna (asc o desc)
function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// Nomi colonne
const rows = [
  { id: 'id', numeric: false, disablePadding: true, label: 'ID' },
  { id: 'nome', numeric: false, disablePadding: false, label: 'IDV' },
  { id: 'zona_di_competenza', numeric: false, disablePadding: false, label: 'Zona di competenza' },
  { id: 'actions', numeric: true, disablePadding: false, label: '' },
];

// intestazione tabella...
class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
        <TableHead>
            <TableRow>
            <TableCell padding="checkbox">
                <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                />
            </TableCell>
            {rows.map(row => {
                return (
                <TableCell
                    key={row.id}
                    numeric={row.numeric}
                    padding={row.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === row.id ? order : false}
                >
                    <Tooltip
                    title="Ordina"
                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                    >
                    <TableSortLabel
                        active={orderBy === row.id}
                        direction={order}
                        onClick={this.createSortHandler(row.id)}
                    >
                        {row.label}
                    </TableSortLabel>
                    </Tooltip>
                </TableCell>
                );
            }, this)}
            </TableRow>
        </TableHead>
    );
  }
}

// toolbar della tabella...
let EnhancedTableToolbar = props => {
  const { selected, numSelected, classes } = props;

  return (
    <Toolbar style={{display:'block'}}>
        <div className="row" style={{height:'64px',lineHeight:'64px'}}>
            <div className="col-xs-5">
                {numSelected > 0 ? (
                <span style={{fontSize:22}}>
                    {numSelected} selezionati
                </span>
                ) : (
                <span style={{fontSize:22}}>
                    IDV
                </span>
                )}
            </div>
            <div className="col-xs-7 text-right">
                <button className="btn btn-warning" onClick={() => alert('call api to export csv')} style={{marginRight:'10px'}}>ESPORTA</button>
                <button className="btn btn-primary" style={{marginRight:'10px'}} onClick={() => { jQuery('#item-modal').modal('show') }}>AGGIUNGI IDV</button>
                <button className="btn btn-primary" data-toggle="modal" data-target="#search-modal">CERCA</button>
            </div>
        </div>
    </Toolbar>
  );
};

// Screen Component
class IdvScreen extends React.Component {

  state = {
    isLoading: true,
    order: 'asc',
    orderBy: 'nome',
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 10,
    // for snackbar 
    snackShow : false,
    snackMessage: '',
    // for searching...
    searchNome: '',
    searchProv: '',
    // for create / edit item...
    itemId: '',
    itemNome: '',
    itemProv: '',
    itemCity: '',
    itemStreet: '',
    itemCap: '',
    itemPiva: '',
    itemReservedPhone: '',
    itemSupportPhone: '',
    itemOwner: '',
    itemZone: [],
    itemInvoiceEmail: '',
    itemMarketingEmail: '',
    itemLogo: '',
    itemPercentage: '',
    // sending data
    sendingData: false
  };

  componentWillMount(){  
    const token = localStorage.getItem('token');
    if(token && token !== null){
        Utility.checkToken(token)
        .then((resp) => resp.json()) // Transform the data into json
        .then((data) => {
            // server response...
            if(data.id == 0){
                this.props.history.push('/')
            } else {
                // controllo se come utente ho abilità a vedere questa screen
                const control = Utility.acl(data.tipologia,'idv').control;
                if(control == false){
                    // redirect a login ... non hai il permesso
                    this.props.history.push('/')
                }
            }
        })
        .catch((error)=>{
            console.log("Verification token API error: " + error.message);
        });
    } else {
        this.props.history.push('/')
    }
}

  componentDidMount(){
      this.getData();
      const that = this;
      jQuery('#item-modal').on('hidden.bs.modal', function (e) {
         that.cleanForm()
      })
  }

  // get Filter string
  getFilter(){
    let searchString = '';
    if(this.state.searchNome != ''){
        searchString += '&nome='+this.state.searchNome;
    }
    if(this.state.searchProv != ''){
        searchString += '&provincia='+this.state.searchProv;
    }
    // tolgo il primo carattere dalla stringa
    searchString = searchString.substr(1);
    // aggiungo il "?" davanti alla stringa
    searchString = "?"+searchString;
    return searchString;
  }

  clearFilter(){
    this.setState({searchNome: ''});
    this.setState({searchProv: ''});
  }
  
  // funziona che preleva e aggiorna i dati della tabella in base anche alla ricerca...
  getData(){
      this.setState({isLoading: true});
      const searchString = this.getFilter();
      // prelevo i dati dal server...
      const url = Config.apiUrl+'/wp-json/griphone/v1/idvs'+searchString;
      fetch(url,{
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
      })
      .then((resp) => resp.json()) // Transform the data into json
      .then((data) => {
          this.setState({data: data});
          this.setState({isLoading: false});
      })
      .catch((error)=>{
          console.log("Idv API call error: " + error.message);
      });
  }

  // preleva i dati di un item da modificare...
  getItemData(id){
      // prelevo i dati dal server...
      const url = Config.apiUrl+'/wp-json/griphone/v1/idv/'+id;
      fetch(url,{
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
      })
      .then((resp) => resp.json()) // Transform the data into json
      .then((data) => {
          console.log(data)
          this.setState({itemId: id});
          this.setState({itemNome: data.nome});
          this.setState({itemProv: data.provincia});
          this.setState({itemCity: data.citta});
          this.setState({itemStreet: data.via});
          this.setState({itemCap: data.cap});
          this.setState({itemPiva: data.piva});
          this.setState({itemReservedPhone: data.telefono_riservato});
          this.setState({itemSupportPhone: data.telefono_supporto_clienti});
          this.setState({itemOwner: data.nome_referente});
          this.setState({itemZone: data.zona_di_competenza});
          this.setState({itemInvoiceEmail: data.email_fatture});
          this.setState({itemMarketingEmail: data.email_comunicazioni});
          this.setState({itemLogo: data.logo});
          this.setState({itemPercentage: data.percentuale});
          jQuery('#item-modal').modal('show');
      })
      .catch((error)=>{
          console.log("Idv API call error: " + error.message);
      });
  }

  cleanForm(){
    this.setState({itemId: ''});
    this.setState({itemNome: ''});
    this.setState({itemProv: ''});
    this.setState({itemCity: ''});
    this.setState({itemStreet: ''});
    this.setState({itemCap: ''});
    this.setState({itemPiva: ''});
    this.setState({itemReservedPhone: ''});
    this.setState({itemSupportPhone: ''});
    this.setState({itemOwner: ''});
    this.setState({itemZone: []});
    this.setState({itemInvoiceEmail: ''});
    this.setState({itemMarketingEmail: ''});
    this.setState({itemLogo: ''});
    this.setState({itemPercentage: ''});
    // pulisco l'input file
    jQuery('#logo-idv').val('');
  }

  // funziona che crea o aggiorna un item...
  saveData(){
    // cambio il pulsante di invio per non far cliccare piu volte...
    this.setState({sendingData: true});
    const s = this.state;
    // form control...
    if(this.formValidation() == true){
      // form ok...
      if(s.itemId == ''){
        // creo item
        // prepare object data...
        let fd = new FormData();
        fd.append('nome',s.itemNome);
        fd.append('via',s.itemStreet);
        fd.append('citta',s.itemCity);
        fd.append('cap',s.itemCap);
        fd.append('provincia',s.itemProv);
        fd.append('piva',s.itemPiva);
        fd.append('telefono_riservato',s.itemReservedPhone);
        fd.append('telefono_supporto_clienti',s.itemSupportPhone);
        fd.append('nome_referente',s.itemOwner);
        fd.append('zona_di_competenza',JSON.stringify(s.itemZone));
        fd.append('email_fatture',s.itemInvoiceEmail);
        fd.append('email_comunicazioni',s.itemMarketingEmail);
        fd.append('percentuale',s.itemPercentage);
        fd.append('logo',s.itemLogo);

        const url = Config.apiUrl+'/wp-json/griphone/v1/createIDV';
        fetch(url,{
          method: "POST",
          body: fd,
          headers: {
              "Authorization": "Bearer " + localStorage.getItem('token')
          },
        })
        .then((resp) => resp.json()) // Transform the data into json
        .then((data) => {
          console.log(data);
          // ricarico i dati
          this.getData();
          // close modal
          jQuery('#item-modal').modal('hide');
          // success message
          this.setState({snackShow:true})
          this.setState({snackMessage:'IDV creato con successo'});
          // other actions
          this.cleanForm();
          this.setState({sendingData: false});
        })
        .catch((error)=>{
            console.log("Idv API call error: " + error.message);
        });
      } else {
        // modifico item
        // prepare object data...
        let fd = new FormData();
        fd.append('nome',s.itemNome);
        fd.append('via',s.itemStreet);
        fd.append('citta',s.itemCity);
        fd.append('cap',s.itemCap);
        fd.append('provincia',s.itemProv);
        fd.append('piva',s.itemPiva);
        fd.append('telefono_riservato',s.itemReservedPhone);
        fd.append('telefono_supporto_clienti',s.itemSupportPhone);
        fd.append('nome_referente',s.itemOwner);
        fd.append('zona_di_competenza',JSON.stringify(s.itemZone));
        fd.append('email_fatture',s.itemInvoiceEmail);
        fd.append('email_comunicazioni',s.itemMarketingEmail);
        fd.append('percentuale',s.itemPercentage);
        fd.append('logo',s.itemLogo);
        
        const url = Config.apiUrl+'/wp-json/griphone/v1/editIDV/'+s.itemId;
        fetch(url,{
          method: "POST",
          body: fd,
          headers: {
              "Authorization": "Bearer " + localStorage.getItem('token')
          },
        })
        .then((resp) => resp.json()) // Transform the data into json
        .then((data) => {
          console.log(data);
          // ricarico i dati
          this.getData();
          // close modal
          jQuery('#item-modal').modal('hide');
          // success message
          this.setState({snackShow:true})
          this.setState({snackMessage:'IDV modificato con successo'});
          // other actions...
          this.cleanForm();
          this.setState({sendingData: false});
        })
        .catch((error)=>{
            console.log("Idv API call error: " + error.message);
        });
      }
    } else {
      // errori di validazione form...
      alert(this.formValidation());
      // other actions...
      this.setState({sendingData: false});
    }
  }

  // validazione dei campi del form
  formValidation(){
    const s = this.state;

    let form = [
      {
        fieldValue: s.itemNome,
        fieldName: 'Nome',
        rules: ['required']
      },
      {
        fieldValue: s.itemProv,
        fieldName: 'Provincia',
        rules: ['required']
      },
      {
        fieldValue: s.itemCity,
        fieldName: 'Città',
        rules: ['required']
      },
      {
        fieldValue: s.itemStreet,
        fieldName: 'Via',
        rules: ['required']
      },
      {
        fieldValue: s.itemCap,
        fieldName: 'CAP',
        rules: ['required','numeric']
      },
      {
        fieldValue: s.itemPiva,
        fieldName: 'P.iva',
        rules: ['required']
      },
      {
        fieldValue: s.itemReservedPhone,
        fieldName: 'Telefono riservato',
        rules: ['required','numeric']
      },
      {
        fieldValue: s.itemOwner,
        fieldName: 'Referente',
        rules: ['required']
      },
      {
        fieldValue: s.itemZone,
        fieldName: 'Zona di competenza',
        rules: ['required']
      },
      {
        fieldValue: s.itemInvoiceEmail,
        fieldName: 'Email fatture',
        rules: ['required','isEmail']
      },
      {
        fieldValue: s.itemLogo,
        fieldName: 'Logo',
        rules: ['required']
      },
      {
        fieldValue: s.itemPercentage,
        fieldName: 'Percentuale',
        rules: ['required','numeric']
      },
    ];

    // true or errors list
    const result = Utility.formValidator(form);

    return result;
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
        <div>
          <MuiThemeProvider theme={theme}>
            <Snackbar
              anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
              open={this.state.snackShow}
              autoHideDuration={5000}
              onClose={() => {this.setState({snackShow:false}); this.setState({snackMessage:''}) }}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id" style={{fontSize:'14px'}}>{this.state.snackMessage}</span>}
            />
            <EnhancedTableToolbar numSelected={selected.length} selected={selected} />
            <div>
                <Table aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={this.handleSelectAllClick}
                        onRequestSort={this.handleRequestSort}
                        rowCount={data.length}
                    />
                    <TableBody>
                    {stableSort(data, getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(n => {
                        const isSelected = this.isSelected(n.id);
                        return (
                            <TableRow
                            hover
                            onClick={event => this.handleClick(event, n.id)}
                            role="checkbox"
                            aria-checked={isSelected}
                            tabIndex={-1}
                            key={n.id}
                            selected={isSelected}
                            >
                              <TableCell padding="checkbox">
                                  <Checkbox checked={isSelected} />
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                  {n.id}
                              </TableCell>
                              <TableCell>
                                {n.nome}
                              </TableCell>
                              <TableCell>
                                {n.zona_di_competenza.toString()}
                              </TableCell>
                              <TableCell numeric>
                                <a className="btn btn-default" onClick={() => this.getItemData(n.id)}>
                                  <span className="glyphicon glyphicon-pencil"></span>
                                </a>
                              </TableCell>
                            </TableRow>
                        );
                        })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={6} />
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
            </div>
            <TablePagination
                style={{fontSize:14}}
                labelRowsPerPage="Righe per pagina: "
                labelDisplayedRows={({ from, to, count }) => from+"-"+to+" di " +count}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
        </MuiThemeProvider>

          {/* modal ricerca */}
          <div className="modal fade" id="search-modal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                  <h4 className="modal-title" id="myModalLabel">Ricerca avanzata</h4>
                </div>
                <div className="modal-body">

                  <label>Nome IDV</label>
                  <input type="text" value={this.state.searchNome} className="form-control" onChange={(text) => this.setState({searchNome: text.target.value})} />
                  <br></br>
                  <label>Provincia</label>
                  <select className="form-control" value={this.state.searchProv} onChange={(text) => this.setState({searchProv: text.target.value})}>
                    <option value="">Scegli provincia</option>
                      {
                        Config.province.map((value,index) => (
                          <option key={value} value={value}>{value}</option>
                        ))
                      }
                  </select>
                  <br></br>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default" onClick={() => this.clearFilter()} >PULISCI FILTRI</button>
                  <button type="button" className="btn btn-primary" onClick={()=>this.getData()} data-dismiss="modal">CERCA</button>
                </div>
              </div>
            </div>
          </div>

          {/* modal item */}
          <div className="modal fade" id="item-modal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                  <h4 className="modal-title" id="myModalLabel">
                    {
                      this.state.itemId != ''
                      ?
                      <span>{this.state.itemNome}</span>
                      :
                      <span>Crea IDV</span>
                    }
                  </h4>
                </div>
                <div className="modal-body">
                  <form encType="multipart/form-data">
                  <div className="row">
                      <div className="col-xs-6">
                        <label>Nome IDV *</label>
                        <input type="text" value={this.state.itemNome} className="form-control" onChange={(text) => this.setState({itemNome: text.target.value})} />
                        <br></br>

                        <label>Provincia *</label>
                        <select className="form-control" value={this.state.itemProv} onChange={(text) => this.setState({itemProv: text.target.value})}>
                          <option value="">Scegli provincia</option>
                            {
                              Config.province.map((value,index) => (
                                <option key={value} value={value}>{value}</option>
                              ))
                            }
                        </select>
                        <br></br>

                        <label>Città *</label>
                        <input type="text" value={this.state.itemCity} className="form-control" onChange={(text) => this.setState({itemCity: text.target.value})} />
                        <br></br>

                        <label>Via *</label>
                        <input type="text" value={this.state.itemStreet} className="form-control" onChange={(text) => this.setState({itemStreet: text.target.value})} />
                        <br></br>

                        <label>CAP *</label>
                        <input type="text" value={this.state.itemCap} className="form-control" onChange={(text) => this.setState({itemCap: text.target.value})} />
                        <br></br>

                        <label>P.iva *</label>
                        <input type="text" value={this.state.itemPiva} className="form-control" onChange={(text) => this.setState({itemPiva: text.target.value})} />
                        <br></br>

                        <label>Telelfono riservato *</label>
                        <input type="text" value={this.state.itemReservedPhone} className="form-control" onChange={(text) => this.setState({itemReservedPhone: text.target.value})} />
                        <br></br>
                      </div>
                      <div className="col-xs-6">
                        <label>Telelfono supporto clienti</label>
                        <input type="text" value={this.state.itemSupportPhone} className="form-control" onChange={(text) => this.setState({itemSupportPhone: text.target.value})} />
                        <br></br>

                        <label>Nome referente *</label>
                        <input type="text" value={this.state.itemOwner} className="form-control" onChange={(text) => this.setState({itemOwner: text.target.value})} />
                        <br></br>
                        
                        <label>Zona di competenza *</label>
                        {
                          this.state.itemZone.length > 0
                          ?
                          <span> ({this.state.itemZone.toString()})</span>
                          :
                          null
                        }
                        <select multiple className="form-control" value={this.state.itemZone} onChange={(text) => { let selection = [...text.target.options].filter(({selected}) => selected).map(({value}) => value); this.setState({itemZone: selection}) }} style={{height:'113px'}}>
                            {
                              Config.province.map((value,index) => (
                                <option key={value} value={value}>{value}</option>
                              ))
                            }
                        </select>
                        <br></br>

                        <label>Email fatture *</label>
                        <input type="text" value={this.state.itemInvoiceEmail} className="form-control" onChange={(text) => this.setState({itemInvoiceEmail: text.target.value})} />
                        <br></br>

                        <label>Email comunicazioni</label>
                        <input type="text" value={this.state.itemMarketingEmail} className="form-control" onChange={(text) => this.setState({itemMarketingEmail: text.target.value})} />
                        <br></br>

                        <label>Logo *</label>
                        {
                          this.state.itemId != '' &&  typeof this.state.itemLogo == 'string'
                          ?
                          <div style={{margin: '10px 0'}}>
                          <img src={this.state.itemLogo} style={{width:'70px',height:'auto'}} />
                          </div>
                          :
                          null
                        }
                        <input type="file" id="logo-idv" className="form-control" onChange={(text) => { const file = text.target.files[0]; this.setState({itemLogo: file}); console.log(file) }} />
                        <br></br>

                        <label>Percentuale riconosciuta a Griphone *</label>
                        <input type="text" value={this.state.itemPercentage} className="form-control" onChange={(text) => this.setState({itemPercentage: text.target.value})} />
                        <br></br>
                      </div>
                  </div>
                  </form>

                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal">CHIUDI</button>
                  {
                    this.state.sendingData == false
                    ?
                    <button type="button" className="btn btn-primary" onClick={()=> this.saveData()}>SALVA</button>
                    :
                    <button type="button" className="btn btn-primary" disabled >ATTENDI</button>
                  }
                </div>
              </div>
            </div>
          </div>


        </div>

    );
  }
}

export default IdvScreen;
