import React, { Component } from 'react';

class CompletedServicesScreen extends React.Component {
    render(){
        return(
            <div>
                CompletedServicesScreen
            </div>
        )
    }
}

export default CompletedServicesScreen;