import React, { Component } from 'react';

class RequestsReceivedScreen extends React.Component {
    render(){
        return(
            <div>
                RequestsReceivedScreen
            </div>
        )
    }
}

export default RequestsReceivedScreen;