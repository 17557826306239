import React, { Component } from 'react';

class ActiveServicesScreen extends React.Component {
    render(){
        return(
            <div>
                ActiveServicesScreen
            </div>
        )
    }
}

export default ActiveServicesScreen;