import React, { Component } from 'react';

class InvoicesSentScreen extends React.Component {
    render(){
        return(
            <div>
                InvoicesSentScreen
            </div>
        )
    }
}

export default InvoicesSentScreen;