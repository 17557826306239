import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'

import Config from '../../Config'

import Utility from '../../Utility'

class DashboardScreen extends React.Component {
    
    componentWillMount(){  
        const token = localStorage.getItem('token');
        if(token && token !== null){
            Utility.checkToken(token)
            .then((resp) => resp.json()) // Transform the data into json
            .then((data) => {
                // server response...
                if(data.id == 0){
                    this.props.history.push('/')
                } else {
                    // controllo se come utente ho abilità a vedere questa screen
                    const control = Utility.acl(data.tipologia,'dashboard').control;
                    if(control == false){
                        // redirect a login ... non hai il permesso
                        this.props.history.push('/')
                    }
                }
            })
            .catch((error)=>{
                console.log("Verification token API error: " + error.message);
            });
        } else {
            this.props.history.push('/')
        }
    }

    render(){
        return(
            <div>
                DashboardScreen
            </div>
        )
    }
}

export default withRouter(DashboardScreen);